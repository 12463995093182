import React from "react"
import styles from "../styles/footer.module.css"
import { Link as GatsbyLink } from "gatsby"

const Footer = () => {
  return (
    <div className={styles.main}>
      <div className={styles.upper}>
        <h1 className={styles.text}>Ich freue mich auf deine Nachricht!</h1>
        <span className={styles.paragraph}>
          Du hast Fragen zu meinen Produkten? Meine Beratungstermine sind für
          dich stets kostenfrei! <br></br>
          <br></br>
          Melde dich unter <a href="tel:+43 650 41 77 113">+43 650 41 77 113</a>
          &nbsp;oder schreibe mir eine Nachricht per{" "}
          <a href="mailto:manuela.wurm@gmx.at"> E-Mail</a>, auf{" "}
          <a
            href="https://www.facebook.comhttps://de-de.facebook.com/beautycoachwurm"
            title="Facebook"
          >
            Facebook
          </a>
          &nbsp;oder benutze das{" "}
          <GatsbyLink to="/contact/">Kontaktformular</GatsbyLink>
        </span>

        <div className={styles.footerBtns}>
          <a
            href="https://de-de.facebook.com/beautycoachwurm"
            title="Facebook"
            className={styles.btnLink}
          >
            besuche mich auf Facebook
          </a>

          <a
            href="https://www.instagram.com/beautycoachwurm/"
            className={styles.btnLink}
          >
            Folge mir auf Instagram
          </a>
        </div>

        <p className={styles.footerP}>
          <strong className={styles.strong}>Manuela Wurm</strong> <br></br>
          Sonnenstraße 18 | 3322 Viehddorf <br></br>
          Tel.: +43 650 41 77 113<br></br>E-Mail: wurm.manuela@gmx.at
        </p>
      </div>
      <div className={styles.lower}>
        <p className={styles.lower}>
          <GatsbyLink to="/about">Impressum</GatsbyLink> |{" "}
          <GatsbyLink to="/privacy">Datenschutz</GatsbyLink> <br></br>
          (c) Manuela Wurm 2021
        </p>
      </div>
    </div>
  )
}

export default Footer
