import React from 'react'
import '../../styles/MobileBtn.css'


const MobileBtn = ({click, active}) => {
    
    return (
        <div className= {`container ${active ? "change": ""}`}
        onClick = {click}
        >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            <hr className="line"></hr>
      </div>
    )
}

export default MobileBtn
