import React from "react"
import Header from "./header"
import Footer from "./footer"
import styles from "../styles/layout.module.css"

function layout({ children }) {
  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <Header />

        <main>{children}</main>
      </div>

      <Footer />
    </div>
  )
}

export default layout
