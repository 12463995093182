import React from "react"
import { Link } from "gatsby"
import styles from "../../styles/mobileMenu.module.css"

const MobileMenu = ({ showMenu }) => {
  return (
    <div
      className={styles.container}
      style={{
        visibility: showMenu ? `visible` : `hidden`,
        width: showMenu ? `250px` : `0px`,
      }}
    >
      <Link to="/" activeClassName={styles.activeBtn} className={styles.btn}>
        home
      </Link>

      <Link
        to="/makeUp/"
        activeClassName={styles.activeBtn}
        className={styles.btn}
      >
        make-up
      </Link>

      <Link
        to="/face/"
        activeClassName={styles.activeBtn}
        className={styles.btn}
      >
        face
      </Link>

      <Link
        to="/bodyHair/"
        activeClassName={styles.activeBtn}
        className={styles.btn}
      >
        body & hair
      </Link>

      <Link
        to="/hands/"
        activeClassName={styles.activeBtn}
        className={styles.btn}
      >
        hands
      </Link>

      <Link
        to="/vitality/"
        activeClassName={styles.activeBtn}
        className={styles.btn}
      >
        vitality
      </Link>
    </div>
  )
}

export default MobileMenu
