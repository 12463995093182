import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import styles from "../styles/header.module.css"
import MobileMenu from "../components/Navbar/MobileMenu"
import MobileBtn from "../components/Navbar/MobileBtn"

const Header = () => {
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "header.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className={styles.header}>
      <MobileMenu showMenu={showMenu} />
      <Link to="/">
        <Image
          fluid={data.file.childImageSharp.fluid}
          className={styles.img}
        ></Image>
      </Link>
      <MobileBtn
        click={toggleMenu}
        active={showMenu}
        className={styles.mobileBtn}
      />
      <h3 className={styles.headertitle}>
        Vital- & Gesundheitsprävention | Beauty & Makeup
      </h3>

      <nav className={styles.menu}>
        <Link to="/" activeClassName={styles.activeBtn} className={styles.btn}>
          home
        </Link>

        <Link
          to="/makeUp/"
          activeClassName={styles.activeBtn}
          className={styles.btn}
        >
          make-up
        </Link>

        <Link
          to="/face/"
          activeClassName={styles.activeBtn}
          className={styles.btn}
        >
          face
        </Link>

        <Link
          to="/bodyHair/"
          activeClassName={styles.activeBtn}
          className={styles.btn}
        >
          body & hair
        </Link>

        <Link
          to="/hands/"
          activeClassName={styles.activeBtn}
          className={styles.btn}
        >
          hands
        </Link>

        <Link
          to="/vitality/"
          activeClassName={styles.activeBtn}
          className={styles.btn}
        >
          vitality
        </Link>
      </nav>
    </section>
  )
}

export default Header
